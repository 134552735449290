import YoutubeVideo from "./YoutubeVideo.js";

function ArticleContent({Data}) {
  return (
    <div className="row justify-content-center pb-5">
      <div className="col col-12 article-cover">
        <div className="article-cover-visible">
          <img 
            src={Data.cover} 
            alt="Article Cover" 
          />
        </div>
      </div>
      {/* FIX: responsive on large screen */}
      <div className="col col-12 col-md-10">
        <div className="row bg-white px-3 py-3">
          <div className="col col-12">
            <h1>{Data.title}</h1>
          </div>

          {Data && Data.content.map( (item, index) => {
            // sub titles
            if (item.type === 0){
              return(
                <div className="col col-12" key={index}>
                  <h2 key={index}>{item.subtitle}</h2>
                </div>
              );
            }

            // paragraphs
            if (item.type === 1){
              return(
                <div className="col col-12" key={index}>
                  <p key={index}>{item.paragraph}</p>
                </div>
              );
            }

            // images
            if (item.type === 2){
              return(
                <div className="col col-12 article-image d-flex justify-content-center pb-2" key={index}>
                  <img 
                    key={index} 
                    src={item.file} 
                    alt={item.alt} 
                  />
                </div>
              );
            }

            // youtube videos
            if (item.type === 3){
              return(
                <div className="col col-12" key={index}>
                  <YoutubeVideo VideoID={item.videoId}/>
                </div>
              );
            }

            // tables
            if (item.type === 4){
              return(
                <div className="col col-12" key={index}>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        {item.headers.map( col => 
                          <th scope="col">{col}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {item.rows.map( row => 
                        <tr>
                          {row.map( cnt => 
                            <td>{cnt}</td>
                          )}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              );
            }

            // Returns span to display nothing to avoid warnings
            return(<span />);
          }
          )}
        </div>
      </div>
    </div>
  );
}

export default ArticleContent;
