import '../../Styles/contactUs.css';
import Logo from "../../images/EAE Insights logo v1.3.svg";

import SocialX from '../../images/SocialMediaIcons/twitter-x.svg';
import SocialInstagram from '../../images/SocialMediaIcons/instagram.svg';
import SocialFace from '../../images/SocialMediaIcons/facebook.svg';
import SocialLinkedin from '../../images/SocialMediaIcons/linkedin.svg';
import SocialWhatsApp from '../../images/SocialMediaIcons/whatsapp.svg';

import LocIcon from '../../images/geo-alt-fill.svg';
import EmailIcon from '../../images/envelope-fill.svg';

export default function ContactUs () {
  return (
    <div id="contact_us" className="page-section contact-us row custom-row justify-contenct-center py-3" style={{minHeight: 0}}>
      <div className="col col-12 col-md-6 contactUs-logo">
        <img src={Logo} alt="Logo" />

      </div>
      <div className="col col-12 col-md-6 contact-details">
        <div className="text-center text-md-start mb-2 contact-channels">
          {/*<p>Phone: <a href="tel:+50455555555">5555-5555</a></p>*/}
          <p><img src={EmailIcon} alt="Email" /> Email: <a href="mailto:admin@eaeinsights.com">admin@eaeinsights.com</a></p>
          <p><img src={LocIcon} alt="Address" /> Address: 22 avenida 9 y 10 calle, colonia trejo, frente al ITEE, San pedro sula,  Cortes, Honduras, C.A.</p>
          <a className="btn contact-us-btn" href="/ContactUs">Contact us</a>
        </div>

        <div className="row">
          <div className="col col-12 text-center text-md-start">
            <p className="mb-0">Social Links</p>
          </div>
          <div className="col col-12 link-socials d-flex justify-content-md-start justify-content-center pt-0">
            <a href="https://eaeinsights.com" >
              <img src={SocialX} alt="X logo link" />
            </a>
            <a href="https://eaeinsights.com" > 
              <img src={SocialInstagram} alt="Instagram logo link" />
            </a>
            <a href="https://eaeinsights.com">
              <img src={SocialFace} alt="Facebook logo link" />
            </a>
            <a href="https://eaeinsights.com" >
              <img src={SocialLinkedin} alt="Linkedin logo link" />
            </a>
            <a href="https://wa.me/50432000071" >
              <img src={SocialWhatsApp} alt="WhatsApp logo link" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
