import Cover from "../../../images/blog/background-blog3.svg"
import Image1 from "../../../images/blog/Diagram-Data-pipeline-diagram.jpg"
import Image2 from "../../../images/blog/data_cleaning_cycle.jpg"


// types
// 0: subtitles
// 1: paragraphs
// 2: image files
// 3: youtube videos
// 4: tables
export const Data = {
  title: "Retroalimentación continua",
  cover: Cover,
  url: "/blog/retroalimentacion-continua",
  content: [
    {
      type: 1,
      paragraph: "Las empresas generan datos continuamente desde múltiples fuentes, como sensores, bases de datos y archivos. Estos datos iniciales suelen estar en un estado crudo y no son inmediatamente utilizables en su forma original. Sin un proceso adecuado, pueden resultar difíciles de interpretar o incluso ser inexactos para el análisis.",
    },
    {
      type: 2,
      file: Image1,
    },

    {
      type: 1,
      paragraph: "Para que los datos sean útiles, es necesario un proceso que los limpie y transforme. Este paso es crucial porque los datos en su estado original a menudo contienen errores, valores faltantes o formatos inconsistentes. Sin este tratamiento, la información no puede aportar un valor real a la toma de decisiones.",
    },
    {
      type: 1,
      paragraph: "Aquí es donde los sistemas automatizados de flujo de datos, conocidos como data pipelines, desempeñan un papel fundamental. Estas herramientas recopilan la información de las diversas fuentes, la procesan para eliminar inconsistencias y la convierten en un formato uniforme y accesible. Esto no solo ahorra tiempo, sino que también asegura que los datos estén listos para usarse en análisis posteriores.",
    },
    {
      type: 2,
      file: Image2,
    },

    {
      type: 1,
      paragraph: "Además de la limpieza, los data pipelines garantizan que los datos se almacenen adecuadamente. Una vez procesados, se colocan en bases de datos u otros sistemas de almacenamiento que permiten acceder a ellos de manera eficiente. Este paso es importante para evitar pérdidas de información y facilitar el trabajo de los equipos de análisis.",
    },
    {
      type: 3,
      videoId: "kGT4PcTEPP8",
    },
    {
      type: 1,
      paragraph: "Por último, los sistemas automatizados no solo optimizan el manejo de datos, sino que también mejoran su calidad y confiabilidad. Este enfoque asegura que las decisiones basadas en datos sean más precisas y fundamentadas, ya que se trabaja con información procesada y lista para generar resultados significativos.",
    },
  ]
};
