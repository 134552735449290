import '../../Styles/BeneficiosPage.css';
import { Data } from "./Beneficios2Content";
import YoutubeVideo from "./YoutubeVideo";

function Beneficios1() {
  return (
    <div className="Beneficios1">
      <div id='box' className="row my-5 pt-5 justify-content-center">
        <div className="col col-12 cover">
          <img 
            src={Data.cover} 
            alt="Article Cover" 
          />
        </div>
        {/* FIX: responsive on large screen */}
        <div className="col col-12 col-md-8">
          <div className="row bg-white px-3 px-md-0">
            <div className="col col-12 article-content">
            </div>
            <div className="col col-12">
              <div className="col col-12 w-100 h-100">
                <h1>{Data.title}</h1>
              </div>
            </div>

            {Data && Data.content.map( (item, index) => {
              // sub titles
              if (item.type === 0){
                return(
                  <div className="col col-12">
                    <h2 key={index}>{item.subtitle}</h2>
                  </div>
                );
              }

              // paragraphs
              if (item.type === 1){
                return(
                  <div className="col col-12">
                    <p key={index}>{item.paragraph}</p>
                  </div>
                );
              }

              // images
              if (item.type === 2){
                return(
                  <div className="col col-12 blog-image">
                    <img 
                      key={index} 
                      src={item.file} 
                      alt={item.alt} 
                      className="w-100 h-100"
                    />
                  </div>
                );
              }

              // youtube videos
              if (item.type === 3){
                return(
                  <div className="col col-12">
                    <YoutubeVideo VideoID={item.videoId}/>
                  </div>
                );
              }

              // tables
              if (item.type === 4){
                return(
                  <div className="col col-12">
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          {item.headers.map( col => 
                            <th scope="col">{col}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {item.rows.map( row => 
                          <tr>
                            {row.map( cnt => 
                              <td>{cnt}</td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                );
              }

              // Returns span to display nothing to avoid warnings
              return(<span />);
            }
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Beneficios1;
