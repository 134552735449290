import NavbarSubmenu from "./NavbarSubmenus";

function NavbarProducts () {
  const subMenuName = "Productos"
  // Sets up the content of the navbar product menu
  const productosSubItems = [
    {
      name: "Sensores",
      url: "#",
      subItems: [
        { 
          name: "Sensores de Monitoreo de Condicion", 
          url: "#", 
          subItems: [
            { name: "BCM0001", url: "/BCM0001" },
            { name: "BCM0002", url: "/BCM0002" },
            { name: "BCM0003", url: "/BCM0003" },
            { name: "BCM0004", url: "/BCM0004" }
          ]
        },
      ]
    },
    {
      name: "Soluciones de Sistema",
      url: "#",
      subItems: [
        { 
          name: "Monitoreo", 
          url: "#", 
          subItems: [
            {
              name: "Condition Monitoring Toolkit", 
              url: "/BAV002N"
            }
          ] 
        },
      ]
    },
    {
      name: "Redes Industriales",
      url: "#",
      subItems: [
        {
          name: "Bloque de redes", url: "#", subItems:[
            {name: "Bloque de Redes Ethernet - BN100HM", url: "/BN100HM"},
            {name: "Bloque de Redes Ethernet - BNI006A", url: "/BNI006A"},
            {name: "Bloque de Redes Ethernet - BNI009T", url: "/BNI009T"},
            {name: "Bloque de Redes Ethernet - BNI00H7", url: "/BNI00H7"},

          ]
        }
      ]
    },
    {
      name: "Conectividad",
      url: '#',
      subItems: [
        {
          name: "Cables de doble extremo", url: "#", subItems:[
            {name: "Cables de doble extremo - BCC03RK", url: "/BCC03RK"},
            {name: "Cables de doble extremo - BCC03RM", url: "/BCC03RM"},
            {name: "Cables de doble extremo - BCC0HZF", url: "/BCC0HZF"},
            {name: "Cables de doble extremo - BCC0HZE", url: "/BCC0HZE"}
          ]
        }
      ]
    },
    {
      name: "Fuentes de Alimentacion",
      url: '#',
      subItems: [
        {
          name: "UPS Cabinete de control", url: "#", subItems:[
            {name: "UPS - BAE0111", url: "/BAE0111"}
          ]
        }
      ]
    }
  ];

  return (
    <NavbarSubmenu subMenuName={subMenuName} subMenuList={productosSubItems} />
  );
}

export default NavbarProducts;
