import '../../../Styles/hero_section_columns.css';
import ImgHeroCol1 from "../../../images/hero_column1.svg"
import ImgHeroCol2 from "../../../images/hero_column2.svg"
import ImgHeroCol3 from "../../../images/hero_column3.svg"
import ImgHeroCol4 from "../../../images/hero_column4.svg"

function HeroColumn({title, list, image, url}){
  return(
    <div className="col col-12 col-md-6 col-lg-3 hero-column px-2 mt-5">

      <div className="d-flex flex-column h-100 p-3">

        <div className="hero-column-image">
          {/* TODO: figure out how to paly animation when object is in view */}
          <img src={image} alt="Col display" className="p-3"/>
        </div>

        <div className="hero-column-title mt-1">
          <h1 className="text-center">{title}</h1>
        </div>

        <div className="hero-column-content flex-grow-1">
          {list.map( (item, index) => 
            <p className="text-justify" key={index}>{item}</p>
          )}
        </div>

        <div className="hero-column-btn text-center">
          <a className="btn hero-button" href={url}>Leer más</a>
        </div>

      </div>
    </div>
  );
}

export default function HeroSectionColumns () {
  return (
    <div id="inteligencia" className="page-section">
      <div className="hero-section-columns row custom-row justify-content-center">
        <div className="col-12 mt-5 pt-5">
              <h1 className="site-title text-center">Inteligencia empresarial basada en datos</h1>
            </div>

        <div className="col col-12 col-md-10 px-4 px-md-5">
          <div className="row mb-5 pb-5">
            <HeroColumn 
              image={ImgHeroCol1}
              title="Flujo de datos" 
              url="/blog/flujo-de-datos"
              list={[
                "Las empresas generan una enorme cantidad de datos a partir de diversas fuentes como sensores, bases de datos y archivos. Estos datos crudos, sin embargo, requieren un proceso de limpieza y transformación para ser útiles. Los data pipelines automatizan esta tarea, recolectando, limpiando y almacenando los datos en un formato adecuado para su posterior análisis, asegurando así la calidad y confiabilidad de la información."
              ]} 
            />
            <HeroColumn 
              image={ImgHeroCol2}
              title="Analitica de datos" 
              url="/blog/analitica-de-datos"
              list={[
                "Al aplicar técnicas estadísticas y de aprendizaje automático a los datos históricos y en tiempo real, podemos identificar patrones y relaciones ocultas que nos permiten construir modelos capaces de predecir futuros comportamientos. Estos modelos se convierten en herramientas poderosas para anticipar eventos, optimizar procesos y tomar decisiones más acertadas."
              ]} 
            />
            <HeroColumn 
              image={ImgHeroCol3}
              title="Transformando los datos en valor" 
              url="/blog/transformando-los-datos-en-valor"
              list={[
                "Al desplegar estos modelos en sistemas de producción, podemos obtener predicciones precisas sobre el rendimiento de los equipos, la calidad de los productos y otros indicadores clave. Estos insights se presentan de forma visual y concisa  a través de dashboards para traducir los datos en posibles acciones, permitiendo a los tomadores de decisiones actuar de manera proactiva."
              ]} 
            />
            <HeroColumn 
              image={ImgHeroCol4}
              title="Retroalimentación continua" 
              url="/blog/retroalimentacion-continua"
              list={[
                "Los modelos predictivos no son estáticos, sino que evolucionan con el tiempo. Es fundamental monitorear constantemente su desempeño y realizar ajustes cuando sea necesario. La retroalimentación continua garantiza que los modelos sigan siendo relevantes y precisos, adaptándose a los cambios en las condiciones operativas y a nuevas fuentes de datos."
              ]} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}
