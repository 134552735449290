import Cover from "../../../images/blog/background-blog3.svg"

// types
// 0: subtitles
// 1: paragraphs
// 2: image files
// 3: youtube videos
// 4: tables
export const Data = {
  title: "The test of contents",
  cover: Cover,
  url: "/blog/testing-content",
  content: [
    {
      type: 1,
      paragraph: "This is just a test site",
      },
    {
      type: 1,
      paragraph: "La industria está experimentando una transformación digital sin precedentes, impulsada en gran medida por el Internet de las Cosas Industrial (IIoT) y la Inteligencia Artificial (IA). Estas tecnologías están revolucionando la forma en que las empresas gestionan sus activos, pasando de un enfoque reactivo a uno proactivo en el mantenimiento.",
    },
    {
      type: 1,
      paragraph: "El monitoreo de condición, habilitado por IIoT, permite recopilar datos en tiempo real de diversos sensores instalados en equipos y maquinaria. Estos datos, que incluyen vibraciones, temperatura, presión y otros parámetros críticos, son analizados por algoritmos de IA para identificar patrones y anomalías que pueden indicar un fallo inminente. Al detectar problemas de manera temprana, las empresas pueden programar el mantenimiento de forma preventiva, evitando costosas paradas no planificadas y maximizando la disponibilidad de los equipos.",
    },
    {
      type: 1,
      paragraph: "El mantenimiento predictivo, basado en los datos obtenidos a través del monitoreo de condición y analizados por IA, va más allá de la detección temprana de fallas. Permite predecir cuándo es probable que ocurra una avería, lo que posibilita planificar el mantenimiento de manera óptima. Esto se traduce en una reducción significativa de los costos operativos, ya que se evitan reparaciones innecesarias y se optimizan los recursos.",
    },
    {
      type: 1,
      paragraph: "Además de mejorar la eficiencia operativa, el mantenimiento predictivo ofrece otros beneficios como una mayor seguridad en las instalaciones, al identificar y mitigar riesgos potenciales antes de que se produzcan incidentes. Asimismo, contribuye a una mayor sostenibilidad, al reducir el consumo de energía y la generación de residuos asociados a reparaciones y reemplazos innecesarios.",
      },
    {
      type: 1,
      paragraph: "En conclusión, la combinación de IIoT e IA está transformando radicalmente el mantenimiento industrial. El monitoreo de condición y el mantenimiento predictivo permiten a las empresas pasar de un enfoque reactivo a uno proactivo, optimizando la disponibilidad de los equipos, reduciendo costos y mejorando la seguridad y la sostenibilidad. A medida que estas tecnologías continúan evolucionando, podemos esperar ver avances aún más significativos en la gestión de activos industriales en los próximos años.",
    },
  ]
};
