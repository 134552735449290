import '../../Styles/ServiciosSlide.css';
import Energia from "../../images/CasosAplicados/energia.svg";
import Bcm from "../../images/CasosAplicados/bcm.svg";
import Cmms from "../../images/CasosAplicados/cmms.svg";
import Inventarios from "../../images/CasosAplicados/inventarios.svg";
import PredicionDeFallas from "../../images/CasosAplicados/prediccion de fallas.svg";


import { Carousel } from 'react-bootstrap';

const Servicios = () => {
  const itemInterval=200;

  return (
    <div id="servicios" className="servicios-section">
      <div className="row custom-row h-100 mb-5">
        <div className="col col-12 h-100">
          <Carousel >
            <Carousel.Item interval={itemInterval}> 
              <div className="carousel-image-container">
              <img className="d-block carousel-image" src={Energia} alt="First slide" />
              </div>
              <Carousel.Caption>
                <h3 className="text-black carousel-text-title">Gestion de Energia</h3>
                <p className="text-black">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={itemInterval}>
              <div className="carousel-image-container">
              <img className="d-block carousel-image" src={Bcm} alt="Second slide" />
              </div>
              <Carousel.Caption>
                <h3 className="text-black carousel-text-title">Monitoreo de Condicion</h3>
                <p className="text-black">Test.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={itemInterval}>
              <div className="carousel-image-container">
              <img className="d-block carousel-image" src={Cmms} alt="Third slide" />
              </div>
              <Carousel.Caption>
                <h3 className="text-black carousel-text-title">Control de Recursos</h3>
                <p className="text-black"> pharetra augue mollis interdum.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={itemInterval}>
              <div className="carousel-image-container">
              <img className="d-block carousel-image" src={PredicionDeFallas} alt="Third slide" />
              </div>
              <Carousel.Caption>
                <h3 className="text-black carousel-text-title">Prediccion de Fallas</h3>
                <p className="text-black"> pharetra augue mollis interdum.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={itemInterval}>
              <div className="carousel-image-container">
              <img className="d-block carousel-image" src={Inventarios} alt="Third slide" />
              </div>
              <Carousel.Caption>
                <h3 className="text-black carousel-text-title">Control de Inventarios</h3>
                <p className="text-black"> pharetra augue mollis interdum.</p>
              </Carousel.Caption>
            </Carousel.Item>

          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Servicios;
