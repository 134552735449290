import '../../../Styles/Tecnologias.css';

import Energia from "../../../images/CasosAplicados/energia.svg";
import Inventarios from "../../../images/CasosAplicados/inventarios.svg";
import Bcm from "../../../images/CasosAplicados/bcm.svg";
import Cmms from "../../../images/CasosAplicados/cmms.svg";
import PredicionDeFallas from "../../../images/CasosAplicados/prediccion de fallas.svg";

function TecnologiaContenedor({techImage, techTitle, techDetails, techUrl}){
  return (
    <div className="col col-12 col-md-6 col-lg-3 col-xl-2 tech-column col-md-2 px-2 mt-5">

      <div className="d-flex flex-column h-100 p-3">

        <div className="tech-image">
          <img src={techImage} alt="Tech" className="p-3"/>
        </div>

        <div className="tech-title mt-1">
          <h1>{techTitle}</h1>
        </div>

        <div className="tech-details flex-grow-1">
          {techDetails.map( (item, index) => 
            <p key={index}>{item}</p>
          )}
        </div>

        {/*
        <div className="hero-column-btn text-center">
          <a className="btn hero-button" href={techUrl}>Ver más</a>
        </div>
        */}
      </div>
    </div>
  );
}

export default function Tecnologias () {
  return (
    <div id="casos" className="page-section">
      <div className="tecnologias">
        <div className="row custom-row">
          <div className="col col-12 col-md-12">
            <div className="col-12 mt-5 pt-5">
              <h1 className="tecnologias-titulo site-title text-center">Casos Aplicados</h1>
            </div>
            <div className="tech-list col col-12 mb-5 pb-5">
              <div className="row justify-content-center">

                {/* Tech 1 */}
                <TecnologiaContenedor 
                  techImage={Energia} 
                  techTitle="ENERGIA"
                  techUrl="/blog/flujo-de-datos"
                  techDetails={[
                "El conectar dispositivos y analizar datos, permite optimizar el consumo, predecir fallas, y mejorar el almacenamiento de energía. Esto se traduce en costos reducidos, mayor eficiencia y uso responsable.",
                  ]}
                />

                {/* Tech 2 */}
                <TecnologiaContenedor 
                  techImage={Inventarios} 
                  techTitle="INVENTARIOS"
                  techUrl="/blog/flujo-de-datos"
                  techDetails={[
                    "Al integrar datos de compras, mantenimiento y producción, se puede predecir la demanda, optimizar los niveles de stock, programar mantenimientos preventivos y mejorar la eficiencia en la producción. Esto reduce  costos de almacenamiento y mejora de la productividad.",
                  ]}
                />

                {/* Tech 3 */}
                <TecnologiaContenedor 
                  techImage={Bcm} 
                  techTitle="BCM"
                  techUrl="/blog/flujo-de-datos"
                  techDetails={[
                    "El IIoT revoluciona el BCM en la industria. Al conectar equipos y analizar datos en tiempo real, se optimiza el mantenimiento, se prolonga la vida útil de los activos y se reducen costos imprevistos",
                  ]}
                />

                {/* Tech 4 */}
                <TecnologiaContenedor 
                  techImage={PredicionDeFallas} 
                  techTitle="PREDICCIÓN DE FALLAS"
                  techUrl="/blog/flujo-de-datos"
                  techDetails={[
                    "La predicción de fallas en equipos industriales mediante IIoT se basa en la identificación de patrones anómalos en los datos de los sensores. Estos patrones, que indican un desvío del comportamiento normal del equipo, pueden ser señales tempranas de una falla inminente.",
                  ]}
                />

                {/* Tech 5 */}
                <TecnologiaContenedor 
                  techImage={Cmms} 
                  techTitle="CMMS"
                  techUrl="/blog/flujo-de-datos"
                  techDetails={[
                    "La toma decisiones más informadas basadas en datos concretos mejora la eficiencia de las operaciones. Al aplicar herramientas analíticas al CMMS se puede identificar patrones, optimizar recursos y reducir costos en la planta",
                  ]}
                />


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
