import { useParams } from "react-router";
import '../../Styles/CallToAction.css';
import Navbar from "../Shared/Navbar";
import ContactUs from "../Shared/contactUs";
import Footer from "../Shared/footer";

import ArticleContent from "../Shared/ArticleContent";
import '../../Styles/ArticleContent.css';

// Articles
import { Data as Industria40 } from "../../Components/Blog/Articles/Blog1";
import { Data as TestBlog } from "../../Components/Blog/Articles/Blog2";
import { Data as FlujoDeDatos } from "../../Components/Blog/Articles/Blog3";
import { Data as AnaliticaDeDatos } from "../../Components/Blog/Articles/Blog4";
import { Data as DatosEnValor } from "../../Components/Blog/Articles/Blog5";
import { Data as Retroalimentacion } from "../../Components/Blog/Articles/Blog6";

function BlogPage() {
  var contentList = [
    Industria40,
    FlujoDeDatos,
    TestBlog,
    AnaliticaDeDatos,
    DatosEnValor,
    Retroalimentacion,
  ];

  var content = null;
  let { id } = useParams();

  // TODO: Create a repository of blogs
  for( var blog of contentList) {
    if (id === blog.url.replace("/blog/","")) {
      content = blog;
    }
  };

  return (
    <>
      <Navbar />
      <div className="container main-content">
        { content ?
          <ArticleContent Data={content} />
          : 
          <div className="row">
            <div className="col">
              <h1>404 - Page not found</h1>
            </div>
          </div>
        }

      </div>

      <ContactUs />
      <Footer />
    </>
  );
}
export default BlogPage;
