import '../../Styles/Navbar.css';
import Logo from "../../images/EAE Insights logo v1.3.svg";
import NavbarProducts from './NavbarProducts';
import NavbarBlog from './NavbarBlog';
import { useEffect } from 'react';
import { UpdateMainContentMargin } from '../../Scripts/UpdateMainContainerMargin';

function NavItem({ itemName, itemUrl, subItems }) {
  return (
    <li className="nav-item">
      <a
        className="nav-link text-dark"
        href={itemUrl}
      >
        {itemName}
      </a>
    </li>
  );
}

export default function Navbar() {
  useEffect(() => {
    const handleResize = () => {
      UpdateMainContentMargin();
    };

    // Trigger the function on initial render
    handleResize();

    // Add the event listener for subsequent resizes
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-white">
      <div className="container-fluid">
        <button
          className="navbar-toggler ms-2 my-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between bg-white">
          <ul className="navbar-nav flex-grow-1 p-2">
            <li className="d-none d-md-block">
              <a href="#inicio">
                <img src={Logo} alt="Nav Logo" className="navbar-logo" />
              </a>
            </li>
            <NavItem
              itemName="Inicio"
              itemUrl="/#inicio"
            />

            <NavbarProducts />
            <NavbarBlog />

            <NavItem
              itemName="Inteligencia Empresarial"
              itemUrl="/#inteligencia"
            />

            <NavItem
              itemName="Casos Aplicados"
              itemUrl="/#casos"
            />
            <NavItem
              itemName="Sobre Nosotros"
              itemUrl="/#sobre-nosotros"
            />
            <NavItem
              itemName="Beneficios"
              itemUrl="/#beneficios"
            />
            <NavItem
              itemName="Contact Us"
              itemUrl="/ContactUs"
            />
          </ul>
        </div>
      </div>
    </nav>
  );
}
