function NavbarSubmenu ({subMenuName, subMenuList}) {
  return (
    <li className="nav-item">
      <a
        className="nav-link text-dark"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`.submenu-${subMenuName}-list-collape`}
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        href="/#"
      >
        {subMenuName}
      </a>
      <div className={`submenu-${subMenuName}-list-collape collapse`}>
        <ul className="submenu-list-ul">
          <NavMenus listSubmenu={subMenuList} subMenuName={subMenuName} />
        </ul>
      </div>
    </li>
  );
}

// Recursive function to display the menu and sub menus
function NavMenus ({subMenuName, listSubmenu}) {
  return (
    <>
      {listSubmenu && listSubmenu.map( (item, index) => 
        item.subItems == null || typeof item.subItems == "undefined" ?
          // The actual links 
          <li className="nav-item-submenu nav-item-submenu-final m-1" key={index}>
            <a className="nav-link ms-2" href={item.url}>
              {item.name}
            </a>
          </li>
          :
          // Collapsable Sub menus 
          <li className="nav-item-submenu m-1" key={index}>
            <a
              className="nav-link text-dark ms-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`.submenu-${subMenuName}-${item.name.replaceAll(" ", "")}-collapse`}
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              href="/#"
            >
              {item.name}
            </a>
            <div className={`submenu-${subMenuName}-${item.name.replaceAll(" ", "")}-collapse collapse`}>
              <ul className="submenu-list-ul">
                <NavMenus listSubmenu={item.subItems} />
              </ul>
            </div>
          </li> 
      )}
    </>
  );
}

export default NavbarSubmenu;
