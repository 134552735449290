import React from 'react';
import YouTube from 'react-youtube';

const YoutubeVideo = ({VideoID}) => {
  const onPlayerReady = (event) => {
    // Access the player object if needed
    event.target.pauseVideo();
  };

  return (
    <div>
      <YouTube
        videoId={VideoID}
        opts={{
          height: '350px',
          width: '100%',
          playerVars: {
            autoplay: 0, // Do not autoplay
            rel: 0, // Disable related videos
          },
        }}
        onReady={onPlayerReady}
      />
    </div>
  );
};

export default YoutubeVideo;
