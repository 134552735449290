import NavbarSubmenu from "./NavbarSubmenus";

function NavbarBlog () {
  const subMenuName = "Blog"
  // Sets up the content of the navbar product menu
  const blogSubItems = [
    {
      name: "Inteligencia Empresarial",
      url: "#",
      subItems: [
        { 
          name: "El futuro del mantenimiento industrial: IIoT, IA y el mantenimiento predictivo", 
          url: "/blog/el-futuro-del-mantenimiento-industrial-iiot-ia-y-el-mantenimiento-predictivo",
        },
        {
          name: "Testing blogs",
          url: "/blog/testing-content",
        },
      ],
    },
    {
      name: "Gestion de Datos",
      url: "#",
      subItems: [
        {
          name: "Flujo de datos",
          url: "/blog/flujo-de-datos",
        },
        {
          name:"Transformando los datos en valor",
          url:"/blog/transformando-los-datos-en-valor",
        },
        {
          name:"Retroalimentación continua",
          url:"/blog/retroalimentacion-continua",
        },
        {
          name:"Analitica de datos",
          url:"/blog/analitica-de-datos",
        },
      ]
    },
  ];

  return (
    <NavbarSubmenu subMenuName={subMenuName} subMenuList={blogSubItems} />
  );
}

export default NavbarBlog;
