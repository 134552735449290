import '../../../Styles/hero_section.css';
// import Logo from "../../../images/EAE logo.svg"
// import Logo from "../../../images/EAE Insights logo v1.1.svg";
import Logo5 from "../../../images/EAE Insights logo 5-5 v1.2.svg";
import Logo4 from "../../../images/EAE Insights logo 4-5 v1.2.svg";
import Logo3 from "../../../images/EAE Insights logo 3-5 v1.2.svg";
import Logo2 from "../../../images/EAE Insights logo 2-5 v1.2.svg";
import Logo1 from "../../../images/EAE Insights logo 1-5 v1.2.svg";

export default function HeroSection () {
  return (
    <div id="inicio" className="page-section">
      <div className="hero-section">
        <div className="row custom-row">
          {/* Logo */}
          <div className="col col-12 hero-logo text-end">
            {/* TODO: which side to put the logo? */}
            <img src={Logo5} alt="EAE Insights Logo" className="hero-logo5 mt-3 mt-md-0 me-3 me-md-0"/>
            <img src={Logo4} alt="EAE Insights Logo" className="hero-logo4 mt-3 mt-md-0 me-3 me-md-0"/>
            <img src={Logo3} alt="EAE Insights Logo" className="hero-logo3 mt-3 mt-md-0 me-3 me-md-0"/>
            <img src={Logo2} alt="EAE Insights Logo" className="hero-logo2 mt-3 mt-md-0 me-3 me-md-0"/>
            <img src={Logo1} alt="EAE Insights Logo" className="hero-logo1 mt-3 mt-md-0 me-3 me-md-0"/>
          </div>

          {/* Hero description */}
          <div className="col col-12 col-sm-6 hero-discription text-end mb-2">
            <h1 className="me-5">EAE Insights Data & IoT</h1>
            <p className="me-5">BRINDANDO EL MUNDO DE DATOS AL USO Y COMODIDAD DE SU MANOS</p>
          </div>

          {/* Contact Button */}
          <div className="col col-12 col-sm-6 hero-cta text-sm-start text-center mb-3 mt-3 mt-md-0">
            <a className="btn hero-button" href="/ContactUs">Contact us</a>
          </div>

        </div>
      </div>
    </div>
  );
}
