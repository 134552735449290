import Navbar from './Shared/Navbar.js'
import HeroSection from '../Components/homePage/HeroSection/hero_section.js'
import HeroSectionColumns from '../Components/homePage/HeroSection/hero_section_columns.js'
import Tecnologias from '../Components/homePage/Tecnologias/Tecnologias.js'
import Servicios from '../Components/Servicios/ServiciosSection.js'
// import SobreNosotros from '../Components/homePage/AboutUs/Sobre_nosotros.js'
// import Beneficios from '../Components/Benefits/Beneficios.js'
// import SobreNosotrosMasInformacion from '../Components/homePage/AboutUs/Sobre_nosotros_mas_informacion.js'
// import Ideas from '../Ideas.js'
// import CTA from '../Components/ContactUs/CTA.js'
import ContactUs from './Shared/contactUs.js'
import Footer from './Shared/footer.js'
import SectionDivider from './Shared/SectionDivider.js'
import '../Styles/main.css';

function HomePage() {
  return (
    <>
      <Navbar />

      <HeroSection />
      <HeroSectionColumns />
      <SectionDivider 
        color1={[250, 250, 250]} 
        color2={[255, 242, 225]}/>
      <Tecnologias />
      <SectionDivider 
        color1={[255, 242, 225]} 
        color2={[234, 216, 192]}/>
      <Servicios />
      <SectionDivider 
        color1={[234, 216, 192]}
        color2={[255, 242, 225]}/>
      {/*
      <SobreNosotros />
      <Beneficios />
      <Ideas />
      <SobreNosotrosMasInformacion />
      <CTA />
      */}
      <ContactUs />
      <Footer />
    </>
  );
}

export default HomePage;
